@font-face {
  font-family: 'Baloo2';
  src: url(../public/assets/fonts/Baloo2-Regular.ttf)
}

@font-face {
  font-family: 'Baloo2ExtraBold';
  src: url(../public/assets/fonts/Baloo2-ExtraBold.ttf)
}

@font-face {
  font-family: 'RifficFree';
  src: url(../public/assets/fonts/RifficFree-Bold.ttf);
}


@font-face {
  font-family: "BlackOpsOne";
  src: url(../public/assets/fonts/BlackOpsOne-Regular.ttf);
}

h1,h2,h3,h4,h5{
  font-family: 'Baloo2ExtraBold';
}

p {
  font-family: 'Baloo2';
  font-weight: 600;
}

.formlabel {
  font-family: 'Baloo2';
  font-weight: 700;
}

.formcontrol {
  font-family: 'Baloo2';
  border: none !important;
}

.full{
  padding: 10% 0% 21%;
}

.confr{
  margin: 20px 0px 40px;
  font-size: 40px!important;
  text-align: center;
}

.login {
  background: url(../public/assets/images/beer.png) !important;
  background-size: cover;
  background-repeat: no-repeat;
}


.btsicon{
  font-size: 60px;
  margin-bottom: 20px;
}

:root {
  font-family: 'Baloo2'!important;
}

.main-heading {
  text-align: center
}

.register {
  padding: 30px;
}

.form-box {
  padding: 5%;
  border-radius: 10px;
}


.main-heading h2 {
  font-family: 'RifficFree'!important;
  font-size: 45px!important;
  display: inline-block;
  position: relative;
  color: #ffffff;
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: #808080;
  filter: drop-shadow(0px 3px 0px #323232);
  border-bottom: 3px solid white;
  text-transform: uppercase;
}

.text-center p {
  text-align: center;
}

.modal-dialog{
  max-width: 450px!important;
  margin: 200px 0px 0px 430px!important;
}

.modal-body{
  padding: 40px 10px 0px!important;
  font-weight: 600;
  text-align: center;
  font-size: 30px;
}

.main-heading h2:before {
  content: '';
  position: absolute;
  bottom: 0;
  left: -5px;
  width: 20px;
  height: 20px;
  transform: rotate(45deg) translateY(1.5px);
  transform-origin: left bottom;
  background-color: white
}

.primary{
  background-color: rgba(228, 228, 228, 0)!important;
  border: none!important;
  text-decoration: underline!important;
  color: #0f0f0f!important;
  font-weight: 600!important;
  margin: 0!important;
  padding: 0!important;
}

.main-heading h2:after {
  content: '';
  position: absolute;
  bottom: 0;
  right: -5px;
  width: 20px;
  height: 20px;
  transform: rotate(-45deg) translateY(1.5px);
  transform-origin: right bottom;
  background-color: white
}

.puber{
  left: 0px;
  bottom: 0px;
  position: fixed;
  width: 400px;
}

.main-btn {
  margin-bottom: 10px;
  padding: 10px 35px!important;
  background-color: rgb(228, 228, 228)!important;
  box-shadow: 0 5px 5px #00000040!important;
  border-radius: 15px!important;
  color: rgb(34, 27, 17)!important;
  font-weight: 700!important;
  border: 4px outset !important;
  font-size: 20px!important;
}


.button-area{
text-align: center;
margin: 20px;
}

.confirm{
  padding: 30px;
}


.danger {
  position: relative;
  text-align: center;
  width: 100%;
  height: 100%;
  background-color: #be000075 !important;
  z-index: -1;
}

.sucess {
  position: relative;
  text-align: center;
  margin: 30px 0px 0px 35px;
  width: 80%;
  height: 100%;
  padding: 10px !important;
  border-radius: 20px;
  background-color: #2222226b !important;
  z-index: -1;

}

a{
  color: #692404 !important;
  
}


@media (max-width:991px) {
  .modal-dialog{
    max-width: 300px!important;
    margin: 200px 20px 0px 30px!important;
  }
  
  .modal-body{
    padding: 40px 10px 0px!important;
    font-weight: 600;
    text-align: center;
    font-size: 20px;
  }


  .confirm{
    padding: 20px;
  }

  .full{
    padding: 20% 0% 90%;
  }
  .puber{
    display: none;
  }

  .main-heading h2 {
    margin-top: 35px;
    font-size: 50px;
    padding: 0 38px 5px
  }

  .loginz{
    font-size: 30px;
  }


  p {
    font-size: 20px;
  }

  a {
    font-size: 20px;
  }


}

@media (max-width:376px) {
  .full{
    padding: 20% 0% 39%;
  }
}

@media (min-width:992px) {
  .main-heading h2 {
    font-size: 30px;
    padding: 0 12px 5px;
  }
  .loginz {
    font-size: 40px;
  }

  label{
    font-size: 20px;
  }

  a {
    font-size: 18px;
  }

}

@media (min-width:1400px) {
  .btsicon{
    font-size: 80px;
    margin-bottom: 40px;
  }

  .modal-dialog{
  max-width: 800px!important;
    margin: 400px 600px!important;
  }
  .modal-body{
    padding: 60px 60px 0px!important;
    font-weight: 600;
    text-align: center;
  }
  .modal-body p{
    font-size: 50px!important;
  }

  .main-heading h2 {
    margin-top: 80px;
    font-size: 60px;
    padding: 0 13px 5px;
  }

  .loginz {
    font-size: 60px;
  }

  p {
    font-size: 30px !important;
  }

  .confr{
    margin: 20px 0px 40px;
    font-size: 60px!important;
    text-align: center;
  }


  .confirm{
    padding: 50px;
  }
  

  label{
    font-size: 30px!important;
  }

  a {
    font-size: 30px;
  }

}